import { computed } from 'vue';
import { useStore } from 'vuex';
import { getSummary } from '@/service/index.js';
import NavHeader from '@/components/NavHeader.vue';
import Login from '@/components/Dialog/Login.vue';
import Register from '@/components/Dialog/Register.vue';
import UpdatePassword from '@/components/Dialog/UpdatePassword.vue';
import ResetPassword from '@/components/Dialog/ResetPassword.vue';
import DocumentationView from '@/components/Documentation/DocumentationView.vue';
import zhLocale from 'element-plus/lib/locale/lang/zh-cn';
import enLocale from 'element-plus/lib/locale/lang/en';
export default {
  components: {
    NavHeader,
    Login,
    Register,
    UpdatePassword,
    ResetPassword,
    DocumentationView
  },
  setup() {
    //data
    const store = useStore();
    const lang = computed(() => store.getters.getLang);
    const locale = computed(() => {
      return store.getters.getLang === 'en' ? enLocale : zhLocale;
    });

    //验证是否登录-没有登录更新初始化header 跳转回首页
    getSummary();
    return {
      lang,
      locale
    };
  }
};