import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "personal-item"
};
const _hoisted_2 = {
  class: "personal-label"
};
const _hoisted_3 = {
  class: "personal-info"
};
const _hoisted_4 = {
  class: "personal-item"
};
const _hoisted_5 = {
  class: "personal-label"
};
const _hoisted_6 = {
  class: "personal-info"
};
const _hoisted_7 = {
  class: "personal-item"
};
const _hoisted_8 = {
  class: "personal-label"
};
const _hoisted_9 = {
  class: "personal-info"
};
const _hoisted_10 = {
  class: "personal-item"
};
const _hoisted_11 = {
  class: "personal-label"
};
const _hoisted_12 = {
  class: "personal-info"
};
const _hoisted_13 = {
  class: "personal-item"
};
const _hoisted_14 = {
  class: "personal-label"
};
const _hoisted_15 = {
  class: "personal-info"
};
const _hoisted_16 = {
  class: "popover-content-box"
};
const _hoisted_17 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_popover = _resolveComponent("el-popover");
  const _component_basic_dialog = _resolveComponent("basic-dialog");
  return _openBlock(), _createBlock(_component_basic_dialog, {
    visible: $props.modelValue,
    title: _ctx.$t('message.PersonalCenter'),
    modal: true,
    customClass: "personal-dialog",
    onCancel: $setup.handleCancel
  }, {
    foot: _withCtx(() => [$setup.disabled ? (_openBlock(), _createBlock(_component_el_popover, {
      key: 0,
      placement: "bottom",
      width: $setup.lang === 'en' ? 210 : 170,
      trigger: "hover",
      teleported: false
    }, {
      reference: _withCtx(() => [_createElementVNode("div", {
        class: "btn disabled-btn",
        style: _normalizeStyle({
          width: $setup.lang === 'en' ? '440px' : '380px'
        })
      }, _toDisplayString(_ctx.$t('message.Processing')), 5)]),
      default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('message.ExpirationDate')) + "：" + _toDisplayString($setup.authorizationInfo.date || ''), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.MaximumInstallationsCount')) + "：" + _toDisplayString($setup.authorizationInfo.count || '') + " " + _toDisplayString(_ctx.$t('message.PCs')), 1), _createElementVNode("p", {
        title: $setup.authorizationInfo.remark || ''
      }, _toDisplayString(_ctx.$t('message.Note')) + "：" + _toDisplayString($setup.authorizationInfo.remark || ''), 9, _hoisted_17)])]),
      _: 1
    }, 8, ["width"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass(["btn", {
        'disabled-btn': !$setup.userInfo.expirationTime
      }]),
      onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => $setup.handleConfirm && $setup.handleConfirm(...args), ["stop"])),
      style: _normalizeStyle({
        width: $setup.lang === 'en' ? '440px' : '380px'
      })
    }, _toDisplayString(_ctx.$t('message.UpdateAuthorization')), 7))]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: "personal",
      style: _normalizeStyle({
        width: $setup.lang === 'en' ? '440px' : '380px'
      })
    }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('message.Company')), 1), _createElementVNode("p", _hoisted_3, _toDisplayString($setup.userInfo.company || ''), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('message.FullName')), 1), _createElementVNode("p", _hoisted_6, _toDisplayString($setup.userInfo.name || ''), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('message.email')), 1), _createElementVNode("p", _hoisted_9, _toDisplayString($setup.userInfo.email || ''), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('message.ExpirationDate')), 1), _createElementVNode("p", _hoisted_12, _toDisplayString($setup.userInfo.expirationTime || _ctx.$t('message.notActive')), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('message.MaximumInstallations')), 1), _createElementVNode("p", _hoisted_15, [_createTextVNode(_toDisplayString(_ctx.$t('message.AllPCs')) + " ", 1), _createElementVNode("span", null, _toDisplayString($setup.userInfo.totalCount || 0), 1), _createTextVNode(" " + _toDisplayString(_ctx.$t('message.PCs')) + " 丨 " + _toDisplayString(_ctx.$t('message.used')) + " ", 1), _createElementVNode("span", null, _toDisplayString($setup.userInfo.usedCount || 0), 1), _createTextVNode(" " + _toDisplayString(_ctx.$t('message.PCs')), 1)])])], 4)]),
    _: 1
  }, 8, ["visible", "title", "onCancel"]);
}