export default {
  name: 'ViewContent',
  props: {
    type: String,
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};