import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c432582"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content-wrap"
};
const _hoisted_2 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_nav_header = _resolveComponent("nav-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  const _component_login = _resolveComponent("login");
  const _component_register = _resolveComponent("register");
  const _component_update_password = _resolveComponent("update-password");
  const _component_reset_password = _resolveComponent("reset-password");
  const _component_documentation_view = _resolveComponent("documentation-view");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["home", {
      en: $setup.lang === 'en'
    }])
  }, [_createVNode(_component_nav_header), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_config_provider, {
    locale: $setup.locale
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  }, 8, ["locale"])])]), _createVNode(_component_login), _createVNode(_component_register), _createVNode(_component_update_password), _createVNode(_component_reset_password), _createVNode(_component_documentation_view)], 2);
}