import { computed } from 'vue';
import { useStore } from 'vuex';
import BasicDialog from '../Basic/BasicDialog.vue';
export default {
  props: {
    //v-model
    modelValue: Boolean
  },
  components: {
    BasicDialog
  },
  emits: ['update:modelValue', 'confirm'],
  setup(props, {
    emit
  }) {
    const store = useStore();
    const lang = computed(() => store.getters.getLang);
    const userInfo = computed(() => store.getters.getUserInfo); //用户信息
    const authorizationInfo = computed(() => store.getters.getAuthorizationInfo); //授权信息
    const disabled = computed(() => {
      return userInfo.value.status === 'Uncheck';
    });

    //点击取消
    function handleCancel() {
      emit('update:modelValue', false);
    }

    //点击确认
    function handleConfirm() {
      if (disabled.value) return;
      if (!userInfo.value.expirationTime) return; //账号未激活
      store.state.authorizationInfo = {};
      emit('confirm');
    }
    return {
      lang,
      userInfo,
      disabled,
      authorizationInfo,
      handleCancel,
      handleConfirm
    };
  }
};