import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "btn-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $setup.dialogVisible = $event),
    title: $setup.dialogTitle,
    width: "640px",
    center: "",
    "custom-class": "form-dialog register-dialog",
    "before-close": $setup.close,
    "close-on-click-modal": false,
    top: "25vh"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      ref: "ruleFormRef",
      model: $setup.ruleForm,
      rules: $setup.rules,
      "label-width": "110px",
      class: "form-box iconfont",
      onKeyup: _withKeys($setup.submit, ["enter"])
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: _ctx.$t('message.company'),
        prop: "company"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.company,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.ruleForm.company = $event),
          modelModifiers: {
            trim: true
          },
          maxlength: "100",
          clearable: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('message.name'),
        prop: "name"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.ruleForm.name = $event),
          modelModifiers: {
            trim: true
          },
          maxlength: "100",
          clearable: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('message.phone'),
        prop: "phone"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.phone,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.ruleForm.phone = $event),
          modelModifiers: {
            trim: true
          },
          maxlength: "50",
          clearable: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('message.email'),
        prop: "email"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.email,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.ruleForm.email = $event),
          modelModifiers: {
            trim: true
          },
          maxlength: "100",
          clearable: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "btn",
        onClick: _cache[4] || (_cache[4] = (...args) => $setup.submit && $setup.submit(...args)),
        onDblclick: _cache[5] || (_cache[5] = (...args) => $setup.noclick && $setup.noclick(...args))
      }, _toDisplayString(_ctx.$t('submit.register')), 33)])]),
      _: 1
    }, 8, ["model", "rules", "onKeyup"])]),
    _: 1
  }, 8, ["modelValue", "title", "before-close"]);
}