import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "tip-content"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_basic_dialog = _resolveComponent("basic-dialog");
  return _openBlock(), _createBlock(_component_basic_dialog, {
    visible: $props.modelValue,
    title: $props.title || _ctx.$t('message.Tips'),
    center: false,
    "show-close": false,
    confirm: $props.confirm,
    width: "510px",
    cancel: $props.cancel || _ctx.$t('message.Cancel'),
    customClass: "tip-dialog",
    onCancel: $setup.handleCancel,
    onConfirm: $setup.handleConfirm
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, () => [_createElementVNode("img", {
      src: require(`../../assets/img/${$props.image}.png`),
      class: "tip-logo"
    }, null, 8, _hoisted_2), _createElementVNode("p", {
      class: "message",
      innerHTML: $props.message
    }, null, 8, _hoisted_3)])])]),
    _: 3
  }, 8, ["visible", "title", "confirm", "cancel", "onCancel", "onConfirm"]);
}