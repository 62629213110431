import BasicDialog from '../Basic/BasicDialog.vue';
export default {
  props: {
    //v-model
    modelValue: Boolean,
    //标题
    title: String,
    //图片名称
    image: {
      type: String,
      default: 'warning'
    },
    //消息
    message: String,
    //确认文本
    confirm: {
      type: String,
      default: ''
    },
    //取消文本
    cancel: {
      type: String,
      default: ''
    }
  },
  components: {
    BasicDialog
  },
  emits: ['update:modelValue', 'confirm'],
  setup(props, {
    emit
  }) {
    //点击取消
    function handleCancel() {
      emit('update:modelValue', false);
    }

    //点击确认
    function handleConfirm() {
      handleCancel();
      emit('confirm');
    }
    return {
      handleCancel,
      handleConfirm
    };
  }
};