import { ArrowRight } from '@element-plus/icons-vue';
export default {
  name: 'MenuList',
  props: {
    index: String,
    label: String,
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    ArrowRight
  }
};