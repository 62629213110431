import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowRight = _resolveComponent("ArrowRight");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_MenuList = _resolveComponent("MenuList", true);
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  return _openBlock(), _createBlock(_component_el_sub_menu, {
    index: $props.index
  }, {
    title: _withCtx(() => [_createVNode(_component_el_icon, {
      class: "el-icon-arrow-right"
    }, {
      default: _withCtx(() => [_createVNode(_component_ArrowRight)]),
      _: 1
    }), _createElementVNode("span", {
      title: $props.label
    }, _toDisplayString($props.label), 9, _hoisted_1)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, idx) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [item.isFolder ? (_openBlock(), _createBlock(_component_MenuList, {
        index: item.id,
        label: item.name,
        list: item.children,
        key: idx
      }, null, 8, ["index", "label", "list"])) : (_openBlock(), _createBlock(_component_el_menu_item_group, {
        key: idx
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu_item, {
          index: item.id,
          title: item.name
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["index", "title"])]),
        _: 2
      }, 1024))], 64);
    }), 256))]),
    _: 1
  }, 8, ["index"]);
}