import "core-js/modules/es.error.cause.js";
import { ref, computed, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { alertMessage } from '@/assets/js/tool.js';
import { registerIde, registerApp } from '@/service/index.js';
export default {
  setup() {
    //data
    const {
      t
    } = useI18n();
    const store = useStore();
    const showRegister = computed(() => store.state.showRegister);
    /**注册身份*/
    const registerIdentity = computed(() => store.state.registerIdentity);
    /**控制dialog显示与隐藏*/
    const dialogVisible = ref(false);
    /**标题*/
    const dialogTitle = ref(t('message.registerIde'));
    const timer = ref(null);

    /**form值*/
    const ruleForm = ref({
      company: '',
      name: '',
      phone: '',
      email: ''
    });

    /**手机号格式验证*/
    const validatePhone = (rule, value, callback) => {
      if (value) {
        let phone = value.replace(/[-]/g, '');
        phone = phone.replace(/\s/g, '');
        if (!isNaN(phone)) {
          callback();
        } else {
          callback(new Error(t('rules.phoneFormat')));
        }
      } else {
        callback();
      }
    };
    /**邮箱格式验证*/
    const validateEmail = (rule, value, callback) => {
      let patt = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (value) {
        if (patt.test(ruleForm.value.email)) {
          callback();
        } else {
          callback(new Error(t('rules.emailFormat')));
        }
      } else {
        callback(new Error(t('rules.email')));
      }
    };
    /**是否为空验证*/
    const validateRequired = (rule, value, callback) => {
      let field = rule.field;
      let errorText = t(`rules.${field}`);
      if (value.trim() === '') {
        callback(errorText);
      } else {
        callback();
      }
    };
    /**验证规则*/
    const rules = ref({
      company: [{
        required: true,
        validator: validateRequired,
        trigger: 'blur'
      }],
      name: [{
        required: true,
        validator: validateRequired,
        trigger: 'blur'
      }],
      phone: [{
        validator: validatePhone,
        trigger: 'blur'
      }],
      email: [{
        required: true,
        validator: validateEmail,
        trigger: 'blur'
      }]
    });

    //ref
    const ruleFormRef = ref(null);

    //watch
    watch(showRegister, async newVal => {
      if (newVal) {
        dialogTitle.value = registerIdentity.value === 1 ? t('message.registerIde') : t('message.registerApp');
      }
      dialogVisible.value = newVal;
    });
    watch(dialogVisible, async newVal => {
      if (newVal) {
        await nextTick();
        ruleForm.value.phone = '';
        ruleFormRef.value.resetFields();
      }
    });

    //function
    /**关闭*/
    function close() {
      store.commit('setShowRegister', false);
    }

    /**提交*/
    async function submit() {
      timer.value = setTimeout(() => {
        ruleFormRef.value.validate(async (valid, fields) => {
          if (valid) {
            //工程师
            if (registerIdentity.value === 1) {
              engineerRegister();
            } else {
              //用户
              userRegister();
            }
          }
        });
      }, 200);
    }

    /**双击不重复单击*/
    function noclick() {
      clearTimeout(timer.value);
    }

    /**工程师注册*/
    async function engineerRegister() {
      return registerIde(ruleForm.value).then(res => {
        if (res.status === 0) {
          alertMessage('success', t('success.register'));
          close();
        } else {
          let text = res ? res.msg || t('failed.register') : t('failed.register');
          alertMessage('error', text);
        }
      });
    }

    /**用户注册*/
    async function userRegister() {
      return registerApp(ruleForm.value).then(res => {
        if (res.status === 0) {
          alertMessage('success', t('success.register'));
          //通知注册成功
          store.commit('setAddUserSuccess');
          close();
        } else {
          let text = res ? res.msg || t('failed.register') : t('failed.register');
          alertMessage('error', text);
        }
      });
    }
    return {
      //data
      dialogVisible,
      dialogTitle,
      ruleForm,
      rules,
      //ref
      ruleFormRef,
      //function
      close,
      submit,
      noclick
    };
  }
};