import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { ref, nextTick, watch, computed } from 'vue';
import { updateAuthorization } from '@/service/index';
import { validateRequired, alertMessage } from '@/assets/js/tool.js';
import BasicDialog from '../Basic/BasicDialog.vue';
export default {
  props: {
    //v-model
    modelValue: Boolean
  },
  components: {
    BasicDialog
  },
  emits: ['update:modelValue', 'confirm'],
  setup(prop, {
    emit
  }) {
    //computed
    const userInfo = computed(() => store.getters.getUserInfo);
    const lang = computed(() => store.getters.getLang);

    //data
    //用户信息
    const {
      t
    } = useI18n();
    const store = useStore();
    const ruleForm = ref({
      date: '',
      count: '',
      remark: ''
    });
    const validatecount = (rule, value, callback) => {
      let errorText = t(`rules.PCs`);
      if (value === '') {
        callback(errorText);
      } else {
        let number = Number(value);
        let totalCount = userInfo.value.totalCount || 0;
        if (isNaN(number)) {
          callback(lang.value === 'en' ? 'Please enter the correct quantity' : '请输入正确的台数');
        } else if (number < totalCount) {
          callback(lang.value === 'en' ? `The requested quantity ${number} is less than the current authorized quantity ${totalCount}` : `申请安装数量${number}小于当前已授权${totalCount}`);
        } else if (number > 9999) {
          callback(t('failed.MaximumIs') + '9999');
        } else {
          callback();
        }
      }
    };
    /**验证规则 实时切换多语言 写在每个item里切换不报错*/
    const rules = ref({
      date: [{
        required: true,
        validator: (rule, value, callback) => {
          validateRequired(value, callback, t('rules.ExpirationDate'));
        },
        trigger: 'change'
      }],
      count: [{
        required: true,
        validator: validatecount,
        trigger: 'blur'
      }],
      remark: [{
        required: true,
        validator: (rule, value, callback) => {
          validateRequired(value, callback, t('rules.Note'));
        },
        trigger: 'blur'
      }]
    });

    //ref
    const ruleFormRef = ref(null);

    //watch
    watch(() => prop.modelValue, async newVal => {
      if (newVal) {
        ruleForm.value = {
          mac: '',
          date: ''
        };
        await nextTick();
        ruleFormRef.value.resetFields();
      }
    });

    //禁用时间选择器输入
    async function handleOpened() {
      await nextTick();
      document.querySelector('.item-date').querySelector('.el-input__inner').readOnly = true;
    }

    //点击取消
    function handleCancel() {
      emit('update:modelValue', false);
    }

    //点击确认
    function handleConfirm() {
      submit();
    }

    //提交
    async function submit() {
      await nextTick();
      ruleFormRef.value.validate(async valid => {
        if (valid) {
          let data = {
            endDay: ruleForm.value.date + ' 23:59:59',
            personLimit: Number(ruleForm.value.count),
            remark: ruleForm.value.remark
          };
          let result = await updateAuthorization(data);
          if (result && result.status === 0) {
            alertMessage('success', t('message.OperationSucceeded'));
            let newUserInfo = JSON.parse(JSON.stringify(userInfo.value));
            newUserInfo.status = 'Uncheck';
            store.commit('setUserInfo', newUserInfo);
            store.commit('setAuthorizationInfo', JSON.parse(JSON.stringify(ruleForm.value)));
            emit('confirm');
          } else {
            let text = result ? result.msg || t('message.OperationFailed') : t('message.OperationFailed');
            alertMessage('error', text);
          }
        }
      });
    }

    //时间范围限制
    function disabledDate(time) {
      let now = Date.now();
      let arter = new Date(now + 1000 * 60 * 60 * 24 * 999);
      return time < now || time > new Date(arter);
    }
    return {
      //data
      userInfo,
      lang,
      ruleForm,
      rules,
      //ref
      ruleFormRef,
      //function
      handleOpened,
      handleCancel,
      handleConfirm,
      submit,
      disabledDate
    };
  }
};