import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form = _resolveComponent("el-form");
  const _component_basic_dialog = _resolveComponent("basic-dialog");
  return _openBlock(), _createBlock(_component_basic_dialog, {
    visible: $props.modelValue,
    title: _ctx.$t('message.UpdateAuthorization'),
    confirm: _ctx.$t('message.SubmitApplication'),
    width: $setup.lang === 'en' ? '680px' : '640px',
    customClass: $setup.lang === 'en' ? 'update-authorization update-authorization-en' : 'update-authorization',
    onCancel: $setup.handleCancel,
    onConfirm: $setup.handleConfirm,
    onOpened: $setup.handleOpened
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      ref: "ruleFormRef",
      model: $setup.ruleForm,
      rules: $setup.rules,
      "label-width": $setup.lang === 'en' ? '200px' : '170px',
      class: "form-box iconfont update-authorization-form",
      onKeyup: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: _ctx.$t('message.ExpirationDate'),
        prop: "date",
        class: "item-date"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_date_picker, {
          modelValue: $setup.ruleForm.date,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.ruleForm.date = $event),
          type: "date",
          "value-format": "YYYY-MM-DD",
          "popper-class": "authorization-date",
          "disabled-date": $setup.disabledDate
        }, null, 8, ["modelValue", "disabled-date"])]),
        _: 1
      }, 8, ["label"]), _createElementVNode("p", {
        style: _normalizeStyle({
          'padding-left': $setup.lang === 'en' ? '200px' : '170px',
          'padding-top': $setup.lang === 'en' ? '16px' : '16px'
        })
      }, [_createTextVNode(_toDisplayString(_ctx.$t('message.CurrentExpirationDate')) + "： ", 1), _createElementVNode("span", null, _toDisplayString($setup.userInfo.expirationTime ? $setup.userInfo.expirationTime.split(' ')[0] : ''), 1)], 4), _createVNode(_component_el_form_item, {
        label: _ctx.$t('message.MaximumInstallations'),
        prop: "count",
        class: "item-authorization"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.count,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.ruleForm.count = $event),
          modelModifiers: {
            trim: true
          },
          maxlength: "75",
          onKeyup: _cache[2] || (_cache[2] = $event => $setup.ruleForm.count = ($setup.ruleForm.count = $setup.ruleForm.count || '').replace(/[^0-9]/, '').replace(/[^\x00-\xff]/g, '').replace(/([\s\u3000]*|[\r\n\u3000]*)/gi, ''))
        }, {
          suffix: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('message.PCs')), 1)]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createElementVNode("p", {
        style: _normalizeStyle({
          'padding-left': $setup.lang === 'en' ? '200px' : '170px',
          'padding-top': $setup.lang === 'en' ? '23px' : '16px'
        })
      }, [_createTextVNode(_toDisplayString(_ctx.$t('message.CurrentInstallations')) + "： ", 1), _createElementVNode("span", null, _toDisplayString($setup.userInfo.totalCount || 0) + " " + _toDisplayString(_ctx.$t('message.PCs')), 1)], 4), _createVNode(_component_el_form_item, {
        label: _ctx.$t('message.Note'),
        prop: "remark",
        class: "item-note"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $setup.ruleForm.remark,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.ruleForm.remark = $event),
          maxlength: "255",
          type: "textarea"
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"])]),
      _: 1
    }, 8, ["model", "rules", "label-width"])]),
    _: 1
  }, 8, ["visible", "title", "confirm", "width", "customClass", "onCancel", "onConfirm", "onOpened"]);
}