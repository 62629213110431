import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { ref, computed, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { login, getUserInfo } from '@/service/index.js';
import { getUUid, alertMessage, dateDiffDay, clearUserInfo } from '@/assets/js/tool.js';
import { setLocalStorage, getLocalStorage, clearLocalStorage } from '@/assets/js/storage.js';
import { ElLoading } from 'element-plus';
import useDownLoad from '@/assets/js/use-download.js';
import axios from 'axios';
export default {
  setup() {
    //data
    const {
      t
    } = useI18n();
    const store = useStore();
    const showLogin = computed(() => store.state.showLogin);
    /**控制dialog显示与隐藏*/
    const dialogVisible = ref(false);
    /**是否显示密码*/
    const showPwd = ref(false);
    /**验证码*/
    const captchaUrl = ref('');
    /**form值*/
    const ruleForm = ref({
      account: '',
      password: '',
      captcha: ''
    });

    /**确认密码验证*/
    const validatePass = (rule, value, callback) => {
      let reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (value === '') {
        callback(new Error(t('rules.password')));
      } else if (reg.test(value)) {
        callback(new Error(t('rules.pwdType')));
      } else {
        callback();
      }
    };
    /**是否为空验证*/
    const validateRequired = (rule, value, callback) => {
      let field = rule.field;
      let errorText = t(`rules.${field}`);
      if (value.trim() === '') {
        callback(errorText);
      } else {
        callback();
      }
    };
    /**验证柜子*/
    const rules = ref({
      account: [{
        required: true,
        validator: validateRequired,
        trigger: 'blur'
      }],
      password: [{
        required: true,
        validator: validatePass,
        trigger: 'blur'
      }],
      captcha: [{
        required: true,
        validator: validateRequired,
        trigger: 'blur'
      }]
    });

    //router
    const router = useRouter();

    //ref
    const ruleFormRef = ref(null);

    //watch
    watch(showLogin, async newVal => {
      dialogVisible.value = newVal;
    });
    watch(dialogVisible, async newVal => {
      if (newVal) {
        await nextTick();
        ruleFormRef.value.resetFields();
        //获取验证码
        getCaptcha();
      }
    });

    //function
    /**关闭*/
    async function close() {
      store.commit('setShowLogin', false);
      showPwd.value = false;
    }
    const {
      download
    } = useDownLoad();

    /**提交*/
    async function submit() {
      await nextTick();
      //return
      ruleFormRef.value.validate(async (valid, fields) => {
        if (valid) {
          let loading = ElLoading.service({
            lock: true,
            background: 'rgba(0, 0, 0, 0)'
          });
          let result = await login(ruleForm.value);
          //更新sid
          setLocalStorage('sid', result.data);
          if (result && result.status === 0) {
            //获取登录信息
            let userInfo = await getUserInfo();
            if (userInfo && userInfo.status === 0) {
              loading.close();
              alertMessage('success', t('success.siginIn'));
              let data = userInfo.data || {};
              //未激活账号无到期时间
              data.expirationTime = data.expirationTime || '';
              //保存用户信息
              store.commit('setUserInfo', data);
              //关闭dialog
              close();
              //查看是否有oldDownload 有登录后自动下载
              let oldDownload = getLocalStorage('download');
              if (oldDownload) {
                let data = {
                  ...oldDownload,
                  sid: result.data
                };
                download(data);
              }
              //查看是否有toPage 有 登录成功后直接跳转到该页面
              let toPage = getLocalStorage('toPage');
              if (toPage) router.push(toPage);
              //计算到期天数
              if (data.expirationTime) {
                //到期时间必须大于当前时间
                if (new Date(data.expirationTime).getTime() < new Date().getTime()) return;
                let days = dateDiffDay(data.expirationTime);
                store.state.expirationDays = days;
              }
            } else {
              loading.close();
              loginFailed(userInfo);
            }
          } else {
            loading.close();
            loginFailed(result);
          }
        }
      });
    }

    /**登录失败*/
    function loginFailed(result = {}) {
      let text = result ? result.msg || t('failed.siginIn') : t('failed.siginIn');
      alertMessage('error', text);
      //更新验证码
      ruleForm.value.captcha = '';
      clearUserInfo();
      getCaptcha();
    }

    /**获取验证码*/
    function getCaptcha() {
      let timestamp = new Date().getTime();
      let uuid = getLocalStorage('sid');
      if (!uuid) {
        uuid = getUUid();
        setLocalStorage('sid', uuid);
      }
      captchaUrl.value = axios.defaults.baseURL + '/captcha' + `?sid=${uuid}&timestamp=${timestamp}`;
    }

    /**点击重置密码*/
    function clickResetPwd() {
      close();
      clearLocalStorage('resetEmail');
      store.commit('setShowResetPwd', true);
    }

    /**点击注册*/
    function clickRegister() {
      close();
      store.commit('setRegisterIdentity', 1);
      store.commit('setShowRegister', true);
    }
    return {
      //data
      dialogVisible,
      showPwd,
      ruleForm,
      rules,
      captchaUrl,
      //ref
      ruleFormRef,
      //function
      close,
      submit,
      getCaptcha,
      clickResetPwd,
      clickRegister
    };
  }
};